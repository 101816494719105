<template>
  <div id="docSearch">
    <img
      id="sliderImage"
      :class="windowWidth >= 1200 ? 'SliderStyle' : 'SliderStyleMobile'"
      alt=""
      src="@/assets/images/Patient/doctorbanner.jpg"
    />
    <div style="height: 44vh" v-if="windowWidth >= 1200"></div>
    <div id="searchDev" :class="windowWidth >= 1200 ? 'searchPosition' : ''">
      <div class="vx-row w-full">
        <feather-icon
          @click="back()"
          :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
          style="width: 30px"
        >
        </feather-icon>
        <p class="text-xl font-bold">
          {{ $t("Doctors") }}
        </p>
      </div>

      <div class="search w-full mt-10">
        <div
          class="searchCard"
          :class="[windowWidth > 500 ? 'searchCard' : 'searchCardMobil']"
        >
          <vs-row vs-type="flex" vs-w="12" style="margin-top: -30px">
            <vs-col
              vs-type="flex"
              :class="[windowWidth > 500 ? '' : 'mt-3']"
              vs-justify="center"
              vs-align="center"
              vs-w="2.8"
              vs-sm="12"
            >
              <feather-icon
                icon="CommandIcon"
                class="cursor-pointer m-1"
              ></feather-icon>
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                style="width: 80%; border: none !important"
                id="specialyMobile"
                label="Name"
                @input="GetSimpleDoctors()"
                v-model="search.SpecialtyID"
                :options="specialties"
                :placeholder="$t('Specialty')"
                :reduce="(ID) => ID.ID"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w=".2"
              vs-sm="0"
            >
              <div class="divider"></div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2.8"
              vs-sm="12"
            >
              <feather-icon
                icon="MapPinIcon"
                class="cursor-pointer m-1"
              ></feather-icon>
              <!-- countries -->
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                @input="GetCitiesBycountry()"
                style="width: 80%"
                id="destinationMobile"
                label="Name"
                v-model="search.CountryID"
                :options="countries"
                :reduce="(ID) => ID.ID"
                :placeholder="$t('Destination')"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w=".2"
              vs-sm="0"
            >
              <div class="divider"></div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1.8"
              vs-sm="12"
            >
              <feather-icon
                icon="FlagIcon"
                class="cursor-pointer m-1"
              ></feather-icon>
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                style="width: 80%"
                label="Name"
                v-model="search.CityID"
                :options="cities"
                @input="UpdateSpecialities()"
                :reduce="(ID) => ID.ID"
                :placeholder="$t('City')"
              />
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w=".2"
              vs-sm="0"
            >
              <div class="divider"></div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2.5"
              vs-sm="12"
            >
              <feather-icon
                icon="UsersIcon"
                class="cursor-pointer m-1"
              ></feather-icon>
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                style="width: 80%"
                label="Name"
                @input="UpdateSpecialities()"
                v-model="search.DoctorID"
                :options="doctors"
                :reduce="(ID) => ID.ID"
                :placeholder="$t('Doctors')"
              />
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              v-if="windowWidth >= 1200"
              vs-w="1.5"
              vs-sm="0"
            >
              <div
                class="greenBackColor searchButton w-full"
                @click="startSearchDoctor"
              >
                <vs-row
                  vs-w="12"
                  class="mt-5"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-col vs-justify="flex-end" vs-align="flex-start" vs-w="4">
                    <h6 class="whitecolor text-bold">
                      {{ $t("Search") }}
                    </h6>
                  </vs-col>
                  <vs-col vs-justify="flex-end" vs-align="center" vs-w="5">
                    <feather-icon
                      style="padding-top: 10px"
                      class="whitecolor ml-5 mr-5"
                      icon="SearchIcon"
                    />
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>

            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="0"
              v-if="windowWidth < 1200"
              vs-sm="12"
              vs-xs="12"
            >
              <div
                class="greenBackColor w-full searchButtonMobil"
                @click="startSearchDoctor"
              >
                <vs-row
                  vs-w="12"
                  class="mt-2"
                  vs-justify="center"
                  vs-align="center"
                >
                  <vs-col vs-justify="flex-end" vs-align="flex-start" vs-w="3">
                    <h4 class="whitecolor text-bold" style="text-align: end">
                      {{ $t("Search") }}
                    </h4>
                  </vs-col>
                  <vs-col vs-justify="flex-end" vs-align="center" vs-w="3">
                    <feather-icon
                      style="padding-top: 10px"
                      class="whitecolor"
                      icon="SearchIcon"
                    />
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
    <!-- <div class="vx-row w-full ml-5 mr-5">
      <div class="lg:w-1/6">
        {{ $t("Filter") }}
      </div>
      <div class="lg:w-1/6">
        <vs-checkbox
          v-model="search.FilterByRecommended"
          :vs-value="true"
          @input="startSearchDoctor"
          >{{ $t("Recommended") }}</vs-checkbox
        >
      </div>

      <div class="lg:w-1/6">
        <vs-checkbox
          v-model="search.FilterByPatientsReviews"
          :vs-value="true"
          @input="startSearchDoctor"
          >{{ $t("PatientsReviews") }}</vs-checkbox
        >
      </div>

      <div class="lg:w-1/6">
        <vs-radio
          v-model="search.FilterByHighstToLowestPrice"
          :vs-value="true"
          @input="search.FilterByLowestToHighstPrice = false"
          @change="startSearchDoctor"
          vs-name="Filter"
          >{{ $t("HighstToLowestPrice") }}</vs-radio
        >
      </div>
      <div class="lg:w-1/6">
        <vs-radio
          v-model="search.FilterByLowestToHighstPrice"
          :vs-value="true"
          @input="search.FilterByHighstToLowestPrice = false"
          @change="startSearchDoctor"
          vs-name="Filter"
          >{{ $t("LowestToHighstPrice") }}</vs-radio
        >
      </div>
      <div class="lg:w-1/6">
        <vx-tooltip :text="$t('ResetFilter')" position="bottom" delay=".3s">
          <feather-icon
            @click="ResetFilter"
            style="width: 20px; height: 20px"
            icon="RefreshCwIcon"
          ></feather-icon>
        </vx-tooltip>
      </div>
    </div> -->
    <!-- <div class="vx-row w-full ml-5 mr-5">
      <div class="lg:w-1/6">
        {{ $t("Filter") }}
      </div>
      <div class="lg:w-1/6">
        <vs-checkbox
          v-model="search.FilterByRecommended"
          :vs-value="true"
          @input="startSearchDoctor"
          >{{ $t("Recommended") }}</vs-checkbox
        >
      </div>

      <div class="lg:w-1/6">
        <vs-checkbox
          v-model="search.FilterByPatientsReviews"
          :vs-value="true"
          @input="startSearchDoctor"
          >{{ $t("PatientsReviews") }}</vs-checkbox
        >
      </div>

      <div class="lg:w-1/6">
        <vs-radio
          v-model="search.FilterByHighstToLowestPrice"
          :vs-value="true"
          @input="search.FilterByLowestToHighstPrice = false"
          @change="startSearchDoctor"
          vs-name="Filter"
          >{{ $t("HighstToLowestPrice") }}</vs-radio
        >
      </div>
      <div class="lg:w-1/6">
        <vs-radio
          v-model="search.FilterByLowestToHighstPrice"
          :vs-value="true"
          @input="search.FilterByHighstToLowestPrice = false"
          @change="startSearchDoctor"
          vs-name="Filter"
          >{{ $t("LowestToHighstPrice") }}</vs-radio
        >
      </div>
      <div class="lg:w-1/6">
        <vx-tooltip :text="$t('ResetFilter')" position="bottom" delay=".3s">
          <feather-icon
            @click="ResetFilter"
            style="width: 20px; height: 20px"
            icon="RefreshCwIcon"
          ></feather-icon>
        </vx-tooltip>
      </div>
    </div> -->
    <div class="w-full mt-1">
      <doctor-list
        class="mt-5"
        v-for="item in searchDoctors"
        :key="item.ID"
        :doctor="item"
      />
    </div>
    <h1
      style="text-align: center"
      v-if="IsShowMore && searchDoctors && searchDoctors.length > 0"
      @click="getNextDoctors"
    >
      {{ $t("ShowMore") }}
    </h1>
  </div>
</template>

<script>
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import DoctorList from "@/views/appointment/search/DoctorList.vue";
import { domain } from "@/gloabelConstant.js";
import CustomPaging from "../../../components/CustomPaging.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";
import VueHorizontal from "vue-horizontal";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
export default {
  components: {
    DoctorList,
    CustomPaging,
  },
  data() {
    return {
      search: {
        SpecialtyID: "",
        CountryID: "",
        CityID: "",
        DoctorID: "",
        IsPaging: true,
        PageNumber: 1,
        PageSize: 10,
        ForAppointment: true,
      },
      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 4,
      IsShowMore: true,
    };
  },
  methods: {
    //     refreshCity(){
    // this.search.CityID = this.search.CityID
    //     },
    UpdateSpecialities() {
      this.$store.dispatch("SpecialtyList/SearchSpecialties", this.search);
      this.getAppointmentCountries();
    },
    GetSimpleDoctors() {
      this.$store.dispatch("DoctorList/SimpleSearchDoctors", this.search);
      this.getAppointmentCountries();
    },
    ResetFilter() {
      this.search = {
        SpecialtyID: "",
        CountryID: "",
        CityID: "",
        DoctorID: "",
        IsPaging: true,
        PageNumber: 1,
        PageSize: 10,
        ForAppointment: true,
      };
      this.startSearchDoctor();
    },
    startSearchDoctor() {
      this.search.PageNumber = 1;
      this.IsShowMore = true;
      this.$store.commit("DoctorList/SET_SearchDoctor", []);
      this.searchDoctor();
    },
    getNextDoctors() {
      debugger;
      this.search.PageNumber++;
      this.searchDoctor();
    },
    clearSearch() {
      this.search.SpecialtyID = "";
      this.search.CityID = "";
      this.search.CountryID = "";

      this.search.IsPaging = true;
      this.search.PageNumber = 1;
    },
    setSelectedCity(Id) {
      this.search.CityID = Id;
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },

    GoNext() {
      this.CurrentPage = this.CurrentPage + 1;
    },
    back() {
      this.$router.go(-1);
    },

    searchDoctor() {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/SearchDoctorsPaging", this.search)
        .then((res) => {
          this.$vs.loading.close();
          debugger;
          debugger;
          if (this.searchDoctors.length == 0) {
            this.$vs.notify({
              title: this.$t("NoData"),
              text: this.$t("NoDataToshow"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          }
          if (res.data.Data.length == 0) {
            this.IsShowMore = false;
          }
          if (
            document.documentElement.scrollHeight <= window.innerHeight + 750 &&
            res.data.Data.length >= this.search.PageSize
          ) {
            debugger;
            this.search.PageNumber++;
            this.searchDoctor();
          }
        });
    },
    selectDestination(destination) {
      this.search.CountryID = destination.ID;
      this.GetCitiesBycountry();
    },
    selectCity(city) {
      debugger;
      this.search.CityID = city.ID;
    },

    GetCitiesBycountry() {
      debugger;
      this.GetSimpleDoctors();
      this.search.CityID = null;
      this.$store.dispatch(
        "CityList/GetAllCitiesByCountryID",
        this.search.CountryID
      );
      this.UpdateSpecialities();
    },
    getAppointmentCountries() {
      this.$store.dispatch(
        "CountryList/GetAllAppointmentCountries",
        this.search
      );
    },
    //  handleScroll(event) {
    //   console.log(window.scrollY);
    // },
  },
  computed: {
    topDestinationsPage() {
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    searchDoctors() {
      debugger;
      return this.$store.state.DoctorList.searchDoctors;
    },
    doctors() {
      debugger;
      return this.$store.state.DoctorList.doctors;
    },
    specialties() {
      debugger;
      return this.$store.state.SpecialtyList.specialties;
    },

    doctorCountries() {
      return this.$store.state.CountryList.doctorCountries;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    cities() {
      return this.$store.state.CityList.cities;
    },
  },
  created() {
    // window.addEventListener("scroll", this.handleScroll);
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    this.$store.commit("DoctorList/SET_SearchDoctor", []);
    this.$store.commit("DoctorList/SET_Doctor", []);

    // if (
    //   !this.$store.state.CountryList.countries ||
    //   this.$store.state.CountryList.countries.length == 0
    // )
    // this.$store.dispatch("CountryList/GetAllDoctorCountries");

    // if (
    //   !this.$store.state.SpecialtyList.specialties ||
    //   this.$store.state.SpecialtyList.specialties.length == 0
    // )

    //this.$store.dispatch("SpecialtyList/GetAllSpecialties");

    debugger;
    const CountryID = this.$route.params.CountryID;
    const SpecialtyID = this.$route.params.SpecialtyID;
    const DoctorID = this.$route.params.DoctorID;
    if (
      CountryID != undefined ||
      SpecialtyID != undefined ||
      DoctorID != undefined
    ) {
      if (SpecialtyID != undefined)
        this.search.SpecialtyID = parseInt(SpecialtyID);
      if (CountryID != undefined) this.search.CountryID = parseInt(CountryID);
      if (DoctorID != undefined) this.search.DoctorID = parseInt(DoctorID);
      this.search.PageNumber = 1;
      this.$store.state.DoctorList.search = this.search;
      this.searchDoctor();
    } else {
      this.search = this.$store.state.DoctorList.search;
      this.search.PageNumber = 1;
      this.search.PageSize = 10;
      this.search.ForAppointment = 1;
      this.search.CityID = null;

      this.searchDoctor();
    }
    this.UpdateSpecialities();
    // if(!this.$store.state.DoctorList.doctors ||
    //   this.$store.state.DoctorList.doctors.length == 0 )
    this.$store.dispatch("CountryList/GetAllAppointmentCountries", this.search);

    this.$store.dispatch("DoctorList/SimpleSearchDoctors", this.search);
  },
  mounted() {
    debugger;
    var sliderDev = document.getElementById("sliderImage");
    var searchDev = document.getElementById("searchDev");
    searchDev.style.top =
      sliderDev.clientHeight - (searchDev.clientHeight + 20) + "px";

    window.onscroll = () => {};
    var vm = this;
    window.onscroll = () => {
      myFunction();
      debugger;
      let bottomOfWindow =
        document.documentElement.scrollTop +
          (window.innerHeight +
            document.getElementById("footer").clientHeight) >=
        document.documentElement.scrollHeight;
      if (bottomOfWindow) {
        if (this.searchDoctors.length > 0) {
          this.getNextDoctors();
        }
      }
    };

    function myFunction() {
      var navbar = document.getElementById("navbarStick");
      var sticky = navbar.offsetTop;
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.onscroll = () => {
      var navbar = document.getElementById("navbarStick");
      var sticky = navbar.offsetTop;
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    };
  },
};
</script>
<style>
.searchPosition {
  position: absolute;
  /* top: 278px; */
  z-index: 107;
  width: 73%;
}
#docSearch .SliderStyleMobile {
  width: 100%;
  z-index: 1;
}
#docSearch .SliderStyle {
  position: absolute;
  top: 0px;
  z-index: 90;
  left: 89px;
  width: 90vw;
  height: 49vh;
  object-fit: cover;
}
</style>
<style>
#specialyMobile .vs__dropdown-toggle {
  border-radius: unset;
}
#destinationMobile .vs__dropdown-toggle {
  border-radius: 18px;
}
</style>
<style scoped>
#docSearch .socialImage {
  width: 50px;
  height: 50px;
}
#docSearch .search {
  z-index: 238;
}
#docSearch .searchCard {
  margin: auto;
  height: flex;
  border-radius: 18px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000003d;

  border-radius: 24px;
  opacity: 1;
}
#docSearch .searchCardMobil {
  margin: auto;
  height: flex;
  border-radius: 18px;
}
#docSearch .searchButton {
  height: 80px;
  width: 150px;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
}
#docSearch .searchButtonMobil {
  height: 50px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}
#docSearch .Hdivider {
  background: #00000029;
  width: 100%;
  height: 2px;
  margin-top: 5px;
}
#docSearch .divider {
  background: #00000029;
  width: 3px;
  height: 80px;
}
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

#docSearch .vs-inputx {
  background-color: $primary;
}
#docSearch .input-span-placeholder {
  color: white;
}
</style>
<style>
#docSearch .vue-flux {
  height: 80vh;
}
#docSearch .vs__dropdown-toggle {
  border: 0px solid #454a62 !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#docSearch .v-select .vs__dropdown-toggle .vs__search {
  font-size: 18px;
}
#docSearch .vs__selected {
  font-size: 15px;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
